import CloseIcon from 'mdi-react/CloseIcon';
import HelpIcon from 'mdi-react/HelpIcon';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import MicrophoneIcon from 'mdi-react/MicrophoneIcon';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import quickLinksData from '../QuickLinks.json';
import Fuse from 'fuse.js';


const MAX_MESSAGES = 50;

const ChatbotPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleChat = () => setIsOpen(!isOpen);

  /*  const [messages, setMessages] = useState([]);
   const [inputText, setInputText] = useState('');



   const handleSubmit = (e) => {
     e.preventDefault();
     if (inputText.trim() !== '') {
       setMessages([...messages, { text: inputText, sender: 'user' }]);
       setInputText('');
       // Here you would typically call a function to get the chatbot's response
       setTimeout(() => {
         setMessages(prev => [...prev, { text: "Coming soon...", sender: 'bot' }]);
       }, 1000);
     }
   }; */

  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [keywordLinks, setKeywordLinks] = useState([]);

  const { finalTranscript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition({
    transcribing: true,
  });

  const messageIdCounter = useRef(0);
  const fuseRef = useRef(null);
  const chatScrollRef = useRef(null);

  useEffect(() => {
    const mappedLinks = []
    quickLinksData.forEach((parent) => {
      parent.child.forEach((child) => {
        child.routes.forEach((route) => {
          mappedLinks.push({
            name: route.name,
            link: route.link,
          });
        });
      });
    });
    setKeywordLinks(mappedLinks);

    fuseRef.current = new Fuse(mappedLinks, {
      keys: ['name'],
      threshold: 0.6,
      distance: 100,
      minMatchCharLength: 2,
    });
  }, []);

  useEffect(() => {
    if (chatScrollRef.current) {
      chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
    }
  }, [messages]);

  const startListening = () => {
    resetTranscript();
    SpeechRecognition.startListening({
      continuous: true,
      language: 'en-US',
    });
  };

  const stopListeningAndSend = () => {
    SpeechRecognition.stopListening();
  };

  const addMessage = (text, sender, results, highlightedText) => {
    setMessages((prevMessages) => {
      const newMessage = { id: messageIdCounter.current++, text, sender, results, highlightedText };
      const updatedMessages = [...prevMessages, newMessage];
      return updatedMessages.slice(-MAX_MESSAGES);
    });
  };

  const highlightKeywords = (text, keywords) => {
    let highlightedText = text;
    keywords.forEach((keyword) => {
      const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
      highlightedText = highlightedText.replace(regex, (match) => `<span class="bg-yellow-200">${match}</span>`);
    });
    return highlightedText;
  };

  const processInput = useCallback((input) => {
    if (fuseRef.current) {
      const results = fuseRef.current.search(input);
      const matches = results.map((result) => result.item);

      const matchedKeywords = matches.map((match) => match.name);
      const highlightedText = highlightKeywords(input, matchedKeywords);

      addMessage(input, 'user', undefined, highlightedText);

      if (matches.length > 0) {
        addMessage(`I found ${matches.length} matching result${matches.length > 1 ? 's' : ''}:`, 'bot', matches);
      } else {
        addMessage(
          "I couldn't find any matches. You can try rephrasing your request or using one of the preset keywords.",
          'bot',
        );
      }
    }
    setInputText('');
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputText.trim()) {
      processInput(inputText);
    }
  };

  useEffect(() => {
    console.log('finalTranscript:', finalTranscript);
    if (finalTranscript) {
      setInputText(finalTranscript);
      processInput(finalTranscript);
      resetTranscript();
    }
  }, [finalTranscript, listening, processInput, resetTranscript]);



  return (
    <div className="position-fixed bottom-0 end-0 m-3" style={{ width: isOpen ? '350px' : '50px', zIndex: 1000 }}>
      {isOpen && (
        <Card style={{ height: '450px', marginBottom: '10px' }}>
          <Card.Header> Chatbot with Push-to-Talk
            <Button
              /*  size="icon"
               variant={listening ? 'default' : 'secondary'}
               onMouseDown={startListening}
               onMouseUp={stopListeningAndSend}
               onTouchStart={startListening}
               onTouchEnd={stopListeningAndSend}
               disabled={!browserSupportsSpeechRecognition} */
              aria-label="Push to talk"
            >
              <MicrophoneIcon className="h-4 w-4" />
            </Button></Card.Header>
          <Card.Body style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
            {messages.map((message, index) => (
              <div key={index} className={`mb-4 ${message.sender === 'user' ? 'text-end' : ''}`}>
                <span className={`p-2 rounded ${message.sender === 'user' ? 'bg-primary text-white' : 'bg-light-blue'}`}>
                  {message.text}
                </span>
              </div>
            ))}
          </Card.Body>
          <Card.Footer>
            <Form onSubmit={handleSubmit}>
              <InputGroup>
                <Form.Control
                  type="text"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  placeholder="Type your message or use push-to-talk..."
                />
                <Button type="submit" variant="primary">Send</Button>
              </InputGroup>
            </Form>
          </Card.Footer>
        </Card>
      )}
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={toggleChat}
          variant="primary"

          className='shadow d-none'
          style={{ width: '50px', height: '50px', padding: 0, borderRadius: '50%', }}
        >
          {isOpen ? <CloseIcon /> : <HelpIcon />}
        </Button>
      </div>
    </div>
  );
};

export default ChatbotPopup;
